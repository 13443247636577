import { useEffect } from "react";
import { useQueryParamValue } from "../useQueryParamValue";
import { saveProgressLocally } from "../progress";
import { decodeChapterSubchapterId } from "../content";
import {parseChapterSubchaptersFromQueryParams} from "../parseChaptersSubchaptersFromQueryParams";

export const useMergeBookmarks = () => {
  const bookmarks = useQueryParamValue<string[]>(
    "bookmarks",
    parseChapterSubchaptersFromQueryParams,
    []
  );

  useEffect(() => {
    bookmarks.forEach((bookmark) => {
      const { chapterId, subchapterId } = decodeChapterSubchapterId(bookmark);
      saveProgressLocally(chapterId, subchapterId);
    });
  }, [bookmarks]);
};
