import React, {useContext, useEffect, useState} from "react";
import css from "./AudioPresentational.module.css";
import VolumeUp from "@mui/icons-material/VolumeUp";
import { StopRounded } from "@mui/icons-material";
import { TranslationsContext } from "../../../helpers/translations";
import Button from "../../../components/Button/Button";
import {useHasChanged} from "../../../helpers/useHasChanged";

interface Props {
  play: () => unknown;
  stop: () => unknown;
  playing: boolean;
  onPlay: () => unknown;
  onStop: () => unknown;
}

function AudioPresentational(props: Props) {
  const { play, stop, playing, onPlay, onStop } = props;
  const translations = useContext(TranslationsContext);
  const hasPlayingChanged = useHasChanged(playing);

  useEffect(() => {
    return () => {
      stop();
    };
  }, [stop]);

  useEffect(() => {
    if (hasPlayingChanged && !playing) {
      onStop();
    }
  }, [playing, hasPlayingChanged, onStop]);

  const playStop = () => {
    if (playing) {
      stop();
    } else {
      play();
      onPlay();
    }
  };

  return (
    <Button
      data-click-analytics-id={"playTextToSpeech"}
      type={"white"}
      className={css.container}
      onClick={playStop}
    >
      <div className={css.speaker}>
        {!playing ? (
          <VolumeUp data-testid={"audio-button-play"} />
        ) : (
          <StopRounded data-testid={"audio-button-stop"} />
        )}
      </div>
      <div className={css.text}>{translations.readAloud}</div>
    </Button>
  );
}

export default AudioPresentational;
