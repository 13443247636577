import React from "react";
import css from "./TextContainer.module.css";
import classNames from "classnames";

interface Props {
  children?: React.ReactNode;
  className?: string;
}

function TextContainer(props: Props) {
  const { children, className } = props;

  return (
    <div className={classNames(css.textContainer, className)}>{children}</div>
  );
}

export default TextContainer;
