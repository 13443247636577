import React from "react";
import css from "./HeaderBack.module.css";
import ArrowBack from "@mui/icons-material/ArrowBack";
import {useTrackTopBackButtonClicked} from "../../helpers/useTrack/useTrack";

function HeaderBack() {
  const track = useTrackTopBackButtonClicked();
  return <ArrowBack className={css.backButton} onClick={track} />;
}

export default HeaderBack;
