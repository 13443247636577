import React from "react";
import { Page } from "../../helpers/content";
import Audio from "./Audio/Audio";
import css from "./SubchapterPage.module.css";
import LayoutPage from "../../components/LayoutPage/LayoutPage";
import { ArrowBackRounded, ArrowForwardRounded } from "@mui/icons-material";
import SpacingContainer from "../../components/SpacingContainer/SpacingContainer";
import Button from "../../components/Button/Button";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import Gallery from "./Gallery/Gallery";
import Text from "./Text/Text";
import Progress from "./Progress/Progress";
import { usePageNavigation } from "./usePageNavigation/usePageNavigation";
import removeMd from "remove-markdown";
import SubchapterPageHeader from "./SubchapterPageHeader/SubchapterPageHeader";
import {
  useTrackBottomBackButtonClicked,
  useTrackBottomForwardButtonClicked,
  useTrackReadAloud,
  useTrackReadAloudStop
} from "../../helpers/useTrack/useTrack";

interface Props {
  chapterId: string;
  subchapterId: string;
  title: string;
  pages: Page[];
  page: Page;
  onNavigateBack: () => unknown;
}

function SubchapterPage(props: Props) {
  const { chapterId, subchapterId, title, pages, page, onNavigateBack } = props;
  const [setPreviousPage, setNextPage] = usePageNavigation(chapterId, subchapterId, pages);
  const trackReadAloud = useTrackReadAloud();
  const trackReadAloudStop = useTrackReadAloudStop();
  const trackBottomBackButtonClicked = useTrackBottomBackButtonClicked();
  const trackBottomForwardButtonClicked = useTrackBottomForwardButtonClicked();
  const totalPages = pages.length;
  const progress = (page.number / totalPages) * 100;
  const imageSrcs = page.images.map((image) => image.src);
  const onBackClicked = () => {
    trackBottomBackButtonClicked();
    setPreviousPage();
  };
  const onForwardClicked = () => {
    trackBottomForwardButtonClicked();
    setNextPage();
  };

  return (
    <LayoutPage
      header={
        <SubchapterPageHeader
          chapterId={chapterId}
          subchapterId={subchapterId}
          title={title}
          onNavigateBack={onNavigateBack}
        />
      }
      pageClassName={css.page}
      page={
        <div className={css.container}>
          <SpacingContainer className={css.spacingContainer}>
            <Audio
              key={page.text}
              text={removeMd(page.text)}
              onPlay={trackReadAloud}
              onStop={trackReadAloudStop}
            />
            {page.images.length > 0 && (
              <Gallery key={imageSrcs.join()} images={page.images} />
            )}
            {page.text && <Text>{page.text}</Text>}
            <Progress
              className={css.progressContainer}
              pageNumber={page.number}
              totalPages={totalPages}
            />
          </SpacingContainer>
          <div className={css.buttonContainer}>
            <div
              style={{ visibility: page.number === 1 ? "hidden" : "visible" }}
            >
              <Button
                data-click-analytics-id={"button-back"}
                data-testid={"button-back"}
                onClick={onBackClicked}
                type={"red"}
                className={css.button}
              >
                <ArrowBackRounded fontSize="large" />
              </Button>
            </div>
            <Button
              data-click-analytics-id={"button-forward"}
              data-testid={"button-forward"}
              onClick={onForwardClicked}
              type={"red"}
              className={css.button}
            >
              <ArrowForwardRounded fontSize="large" />
            </Button>
          </div>
          <div className={css.progressBarContainer}>
            <ProgressBar progress={progress} />
          </div>
        </div>
      }
    />
  );
}

export default SubchapterPage;
