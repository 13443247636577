import React, { useContext } from "react";
import { ConfigContext } from "../../../helpers/config";
import MobileAudio from "./MobileAudio";
import WebAudio from "./WebAudio";

interface Props {
  text: string;
  onPlay: () => unknown;
  onStop: () => unknown;
}

function Audio(props: Props) {
  const { text, onPlay, onStop } = props;
  const config = useContext(ConfigContext);

  if (config.isWebView) {
    return <MobileAudio text={text} onPlay={onPlay} onStop={onStop} />;
  }

  return <WebAudio text={text} onPlay={onPlay} onStop={onStop} />;
}

export default Audio;
