import React, { useContext } from "react";
import css from "./Progress.module.css";
import {
  interpolate,
  TranslationsContext,
} from "../../../helpers/translations";
import classNames from "classnames";

interface Props {
  pageNumber: number;
  totalPages: number;
  className?: string;
}

function Progress(props: Props) {
  const translations = useContext(TranslationsContext);
  const { pageNumber, totalPages, className } = props;

  return (
    <div className={classNames(css.progressContainer, className)}>
      <div className={css.progressText}>
        {interpolate(translations.pageOf, [pageNumber, totalPages])}
      </div>
    </div>
  );
}

export default Progress;
