import React from "react";
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import Index from "./pages/Index/Index";
import {
  Chapter,
  FaqChapter,
  GlossaryChapter,
  isContentChapter,
  isContentChapterWithoutSubchapters,
  isFaqChapter,
  isGlossaryChapter
} from "./helpers/content";
import ChapterPage from "./pages/ChapterPage/ChapterPage";
import SubchapterPage from "./pages/SubchapterPage/SubchapterPage";
import FaqPage from "./pages/FaqPage/FaqPage";
import GlossaryPage from "./pages/GlossaryPage/GlossaryPage";
import SubchapterEndPageFavorite from "./pages/SubchapterEndPage/SubchapterEndPageFavorite/SubchapterEndPageFavorite";
import BookmarksPage from "./pages/BookmarksPage/BookmarksPage";
import { getNumberedChapterTitle } from "./helpers/getNumberedChapterTitle";
import {getChapterRoute, getPageRoute} from "./helpers/navigation";
import {useFrom} from "./helpers/useFrom";

const getComponent = (chapter: FaqChapter | GlossaryChapter) => {
  const title = getNumberedChapterTitle(chapter);

  if (isFaqChapter(chapter)) {
    return <FaqPage title={title} faqEntries={chapter.faqEntries} />;
  }

  if (isGlossaryChapter(chapter)) {
    return (
      <GlossaryPage title={title} glossaryEntries={chapter.glossaryEntries} />
    );
  }

  throw new Error("chapter is invalid");
};

interface Props {
  chapters: Chapter[];
}

function Router(props: Props) {
  const { chapters } = props;
  const from = useFrom();
  const navigate = useNavigate();

  return (
    <Routes>
      <Route path="/" element={<Index chapters={chapters} />} />
      <Route
        path="/bookmarks"
        element={<BookmarksPage chapters={chapters} />}
      />
      {chapters.map((chapter) => {
        if (!isContentChapter(chapter) && !isContentChapterWithoutSubchapters(chapter)) {
          return (
            <Route
              key={chapter.id}
              path={chapter.id}
              element={getComponent(chapter)}
            />
          );
        }

        if (isContentChapterWithoutSubchapters(chapter)) {
          return (
            <Route key={chapter.id} path={chapter.id}>
              <Route index element={<Navigate replace to={getPageRoute(chapter.id, '1', 1)} />} />
              <Route path={'1'}>
                <Route
                  path={"end"}
                  element={
                    <SubchapterEndPageFavorite
                      chapterId={chapter.id}
                      subchapterId={'1'}
                      pages={chapter.pages}
                      onNavigateBack={() => navigate(from || '/')}
                    />
                  }
                />
                {chapter.pages.map(page => {
                  return (
                    <Route
                      key={page.number}
                      path={`${page.number}`}
                      element={
                        <SubchapterPage
                          chapterId={chapter.id}
                          subchapterId={'1'}
                          title={chapter.title}
                          pages={chapter.pages}
                          page={page}
                          onNavigateBack={() => navigate(from || '/')}
                        />
                      }
                    />
                  );
                })}
              </Route>
            </Route>
          );
        }

        return (
          <Route key={chapter.id} path={chapter.id}>
            <Route index element={<ChapterPage chapter={chapter} />} />
            {chapter.subchapters.map((subchapter) => (
              <Route key={subchapter.id} path={`${subchapter.id}`}>
                <Route
                  path={"end"}
                  element={
                    <SubchapterEndPageFavorite
                      chapterId={chapter.id}
                      subchapterId={subchapter.id}
                      pages={subchapter.pages}
                      onNavigateBack={() => navigate(from || getChapterRoute(chapter.id))}
                    />
                  }
                />
                {subchapter.pages.map(page => {
                  return (
                    <Route
                      key={page.number}
                      path={`${page.number}`}
                      element={
                        <SubchapterPage
                          chapterId={chapter.id}
                          subchapterId={subchapter.id}
                          title={subchapter.title}
                          pages={subchapter.pages}
                          page={page}
                          onNavigateBack={() => navigate(from || `/${chapter.id}`)}
                        />
                      }
                    />
                  );
                })}
              </Route>
            ))}
          </Route>
        );
      })}
    </Routes>
  );
}

export default Router;
