import React from "react";
import css from "./FavoriteButton.module.css";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";

interface Props {
  isFavorited?: boolean;
}

function FavoriteButton(props: Props) {
  const { isFavorited } = props;

  if (isFavorited) {
    return (
      <StarRoundedIcon
        data-testid={"favorite-button-favorited"}
        fontSize="inherit"
        className={css.favorited}
      />
    );
  }

  return (
    <StarBorderRoundedIcon
      fontSize="inherit"
      data-testid={"favorite-button-not-favorited"}
    />
  );
}

export default FavoriteButton;
