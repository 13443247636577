import React from "react";
import { GlossaryEntry } from "../../helpers/content";
import css from "./GlossaryEntry.module.css";

interface Props {
  entry: GlossaryEntry;
}

function GlossaryEntryBlock(props: Props) {
  const { word, definition } = props.entry;

  return (
    <div className={css.container}>
      <h3 className={css.word}>{word}</h3>
      <p className={css.definition}>{definition}</p>
    </div>
  );
}

export default GlossaryEntryBlock;
