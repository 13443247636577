import React from "react";
import SubchapterCard from "../../components/SubchapterCard/SubchapterCard";
import { ContentChapter } from "../../helpers/content";
import { Link } from "react-router-dom";
import { getPageRoute } from "../../helpers/navigation";
import SpacingContainer from "../../components/SpacingContainer/SpacingContainer";
import LayoutPage from "../../components/LayoutPage/LayoutPage";
import IndexHeader from "../../components/IndexHeader/IndexHeader";
import Heading from "../../components/Heading/Heading";
import css from "./ChapterPage.module.css";
import Markdown from "../../components/Markdown/Markdown";
import { getNumberedChapterTitle } from "../../helpers/getNumberedChapterTitle";

interface Props {
  chapter: ContentChapter;
}

function ChapterPage(props: Props) {
  const { chapter } = props;
  const { subtitle, subchapters } = chapter;
  const title = getNumberedChapterTitle(chapter);

  return (
    <LayoutPage
      header={<IndexHeader />}
      page={
        <SpacingContainer>
          <Heading>{title}</Heading>
          {subtitle && <Markdown className={css.markdown}>{subtitle}</Markdown>}
          {subchapters.map((subchapter) => {
            const pagePath = getPageRoute(props.chapter.id, subchapter.id, 1);
            return (
              <Link
                data-click-analytics-id={pagePath}
                key={pagePath}
                to={pagePath}
                className={css.link}
              >
                <SubchapterCard chapter={chapter} subchapter={subchapter} />
              </Link>
            );
          })}
        </SpacingContainer>
      }
    />
  );
}

export default ChapterPage;
