import React from "react";
import css from "./SpacingContainer.module.css";
import classNames from "classnames";

interface Props {
  className?: string;
  children?: React.ReactNode;
}

function SpacingContainer(props: Props) {
  const { className, children } = props;
  return (
    <div className={classNames(css.indexContainer, className)}>{children}</div>
  );
}

export default SpacingContainer;
