import React from "react";
import css from "./Header.module.css";

interface Props {
  children?: React.ReactNode;
}

function Header(props: Props) {
  const { children } = props;

  return <div className={css.header}>{children}</div>;
}

export default Header;
