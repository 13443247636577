import {
  getPageRoute,
  getSubchapterEndRoute,
} from "../../../helpers/navigation";
import { Page } from "../../../helpers/content";
import { usePageNumber } from "../../../helpers/usePageNumber";
import { useNavigatePreservingFrom } from "../../../helpers/useNavigatePreservingFrom/useNavigatePreservingFrom";

export const usePageNavigation = (chapterId: string, subchapterId: string, pages: Page[]) => {
  const pageNumber = usePageNumber();
  const navigatePreservingFrom = useNavigatePreservingFrom();

  const setPreviousPage = () => {
    if (pageNumber == null) {
      console.log(`pageNumber is ${pageNumber}`);
      return;
    }

    navigatePreservingFrom(getPageRoute(chapterId, subchapterId, pageNumber - 1), {
      replace: true,
    });
  };
  const setNextPage = () => {
    if (pageNumber == null) {
      console.log(`pageNumber is ${pageNumber}`);
      return;
    }

    if (pageNumber !== pages.length) {
      navigatePreservingFrom(
        getPageRoute(chapterId, subchapterId, pageNumber + 1),
        { replace: true }
      );
    } else {
      navigatePreservingFrom(getSubchapterEndRoute(chapterId, subchapterId), {
        replace: true,
      });
    }
  };

  return [setPreviousPage, setNextPage];
};
