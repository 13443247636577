import { useCallback, useContext, useEffect, useState } from "react";
import { TranslationsContext } from "../../../helpers/translations";
import { UseTtsReturnValue } from "./tts";
import { createUtteranceAsync } from "./createUtterance";

export const useWebTts = (text: string): UseTtsReturnValue => {
  const speechSynthesis = window.speechSynthesis;
  const [playing, setPlaying] = useState(false);
  const translations = useContext(TranslationsContext);
  const [utterance, setUtterance] = useState<
    SpeechSynthesisUtterance | undefined
  >(undefined);

  useEffect(() => {
    if (!speechSynthesis) {
      return;
    }

    createUtteranceAsync(text, translations.languageCode).then((utterance) => {
      utterance.onend = () => {
        setPlaying(false);
      };
      setUtterance(utterance);
    });

    return () => {
      speechSynthesis.cancel();
      speechSynthesis.onvoiceschanged = null;
    };
  }, [speechSynthesis, translations.languageCode, text]);

  const play = useCallback(() => {
    if (utterance) {
      setPlaying(true);
      speechSynthesis.speak?.(utterance);
    }
  }, [utterance, setPlaying, speechSynthesis]);

  const stop = useCallback(() => {
    setPlaying(false);
    speechSynthesis?.cancel();
  }, [setPlaying, speechSynthesis]);

  return [play, stop, playing];
};
