import React from "react";
import {Chapter, Subchapter} from "../../helpers/content";
import css from "./SubchapterCard.module.css";
import {isSubchapterRead} from "../../helpers/progress";
import {useFavorite} from "../../helpers/favorites";
import IndexCard from "../IndexCard/IndexCard";

interface Props {
  chapter: Chapter;
  subchapter: Subchapter;
}

function SubchapterCard(props: Props) {
  const { chapter, subchapter } = props;
  const { title, image } = subchapter;
  const isRead = isSubchapterRead(chapter.id, subchapter.id);
  const [isFavorited] = useFavorite(chapter.id, subchapter.id);

  return (
    <IndexCard
      isRead={isRead}
      showRead={true}
      image={image}
      alt={title}
      isFavorited={isFavorited}
      title={<div className={css.title}>{title}</div>}
    />
  );
}

export default SubchapterCard;
