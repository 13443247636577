import React from "react";
import css from "./Text.module.css";
import TextContainer from "../../../components/TextContainer/TextContainer";
import Markdown from "../../../components/Markdown/Markdown";
import { useLocation } from "react-router-dom";
import {isExternalLink} from "../../../helpers/links";

interface Props {
  children: string;
}

function Text(props: Props) {
  const { children } = props;
  const location = useLocation();

  return (
    <TextContainer className={css.textContainer}>
      <Markdown
        className={css.markdown}
        transformLinkUri={(href) => isExternalLink(href) ? href : `${href}?from=${location.pathname}`}
      >
        {children}
      </Markdown>
    </TextContainer>
  );
}

export default Text;
