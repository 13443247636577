import React from "react";
import ChapterCard from "../../components/ChapterCard/ChapterCard";
import { Link } from "react-router-dom";
import { getChapterRoute } from "../../helpers/navigation";
import SpacingContainer from "../../components/SpacingContainer/SpacingContainer";
import LayoutPage from "../../components/LayoutPage/LayoutPage";
import IndexHeader from "../../components/IndexHeader/IndexHeader";
import {Chapter, isContentChapterWithoutSubchapters} from "../../helpers/content";
import css from "./Index.module.css";
import { close } from "../../helpers/externalActions";
import ChapterWithoutSubchaptersCard
  from "../../components/ChapterWithoutSubchaptersCard/ChapterWithoutSubchaptersCard";

interface Props {
  chapters: Chapter[];
}

function Index(props: Props) {
  const { chapters } = props;
  const onBackClick = () => {
    close();
  };

  return (
    <LayoutPage
      header={<IndexHeader onBackClick={onBackClick} />}
      page={
        <SpacingContainer>
          {chapters.map(chapter => {
            const chapterCard = (() => {
              if (isContentChapterWithoutSubchapters(chapter)) {
                return <ChapterWithoutSubchaptersCard chapter={chapter} />
              }

              return <ChapterCard chapter={chapter} />;
            })();

            return (
              <Link
                data-click-analytics-id={chapter.id}
                key={chapter.id}
                to={getChapterRoute(chapter.id)}
                className={css.link}
              >
                {chapterCard}
              </Link>
            );
          })}
        </SpacingContainer>
      }
    />
  );
}

export default Index;
