import {useAppInsightsContext} from "@microsoft/applicationinsights-react-js";
import {useLocation} from "react-router-dom";

export interface ReadAloud {
  type: 'readAloud';
  payload: {
    url: string;
  };
}

export interface ReadAloudStop {
  type: 'readAloudStop';
  payload: {
    url: string;
  };
}

export interface TopBackButtonClicked {
  type: 'topBackButtonClicked';
  payload: {
    url: string;
  };
}

export interface BottomBackButtonClicked {
  type: 'bottomBackButtonClicked';
  payload: {
    url: string;
  };
}

export interface BottomForwardButtonClicked {
  type: 'bottomForwardButtonClicked';
  payload: {
    url: string;
  };
}

export interface FavoriteChapter {
  type: 'favoriteChapter';
  payload: {
    url: string;
  };
}

export interface UnfavoriteChapter {
  type: 'unfavoriteChapter';
  payload: {
    url: string;
  };
}

export interface DoneChapter {
  type: 'doneChapter';
  payload: {
    url: string;
  };
}

export interface OpenImage {
  type: 'openImage';
  payload: {
    url: string;
    /*
     * imageNumber is string instead of number because in Application Insights numbers go into customMeasurements column
     * and strings go into customDimensions. imageNumber is not a measurement, so it makes sense to make it a string
     * in order for it to be grouped together with other properties in customDimensions.
     * */
    imageNumber: string;
  }
}

export interface CloseImage {
  type: 'closeImage';
  payload: {
    url: string;
    /*
     * imageNumber is string instead of number because in Application Insights numbers go into customMeasurements column
     * and strings go into customDimensions. imageNumber is not a measurement, so it makes sense to make it a string
     * in order for it to be grouped together with other properties in customDimensions.
     * */
    imageNumber: string;
  }
}

export interface OpenFaqItem {
  type: 'openFaqItem';
  payload: {
    question: string;
  };
}

export interface CloseFaqItem {
  type: 'closeFaqItem';
  payload: {
    question: string;
  };
}

export type TrackedEvent =
  ReadAloud |
  ReadAloudStop |
  TopBackButtonClicked |
  BottomBackButtonClicked |
  BottomForwardButtonClicked |
  FavoriteChapter |
  UnfavoriteChapter |
  DoneChapter |
  OpenImage |
  CloseImage |
  OpenFaqItem |
  CloseFaqItem;

const useTrack = (event: TrackedEvent) => {
  const appInsights = useAppInsightsContext();

  return () => {
    appInsights?.trackEvent({ name: event.type }, event.payload);
  };
};

const useTrackPayloadThunk = <T extends TrackedEvent = never>(type: T['type']) => {
  const  appInsights = useAppInsightsContext();

  return (payload: T['payload']) => {
    appInsights?.trackEvent({ name: type }, payload);
  };
};

export const useTrackReadAloud = () => {
  const location = useLocation();
  return useTrack({
    type: 'readAloud',
    payload: {
      url: location.pathname
    }
  });
};

export const useTrackReadAloudStop = () => {
  const location = useLocation();
  return useTrack({
    type: 'readAloudStop',
    payload: {
      url: location.pathname
    }
  });
};

export const useTrackTopBackButtonClicked = () => {
  const location = useLocation();
  return useTrack({
    type: 'topBackButtonClicked',
    payload: {
      url: location.pathname
    }
  });
};

export const useTrackBottomBackButtonClicked = () => {
  const location = useLocation();
  return useTrack({
    type: 'bottomBackButtonClicked',
    payload: {
      url: location.pathname
    }
  });
};

export const useTrackBottomForwardButtonClicked = () => {
  const location = useLocation();
  return useTrack({
    type: 'bottomForwardButtonClicked',
    payload: {
      url: location.pathname
    }
  });
};

export const useTrackFavoriteChapter = () => {
  const location = useLocation();
  return useTrack({
    type: 'favoriteChapter',
    payload: {
      url: location.pathname
    }
  });
};

export const useTrackUnfavoriteChapter = () => {
  const location = useLocation();
  return useTrack({
    type: 'unfavoriteChapter',
    payload: {
      url: location.pathname
    }
  });
};

export const useTrackDoneChapter = () => {
  const location = useLocation();
  return useTrack({
    type: 'doneChapter',
    payload: {
      url: location.pathname
    }
  });
}

export const useTrackOpenImage = () => {
  const location = useLocation();
  const track = useTrackPayloadThunk<OpenImage>('openImage');
  return (imageNumber: number) => {
    track({
      url: location.pathname,
      imageNumber: String(imageNumber),
    })
  };
};

export const useTrackCloseImage = () => {
  const location = useLocation();
  const track = useTrackPayloadThunk<CloseImage>('closeImage');
  return (imageNumber: number) => {
    track({
      url: location.pathname,
      imageNumber: String(imageNumber),
    })
  };
};

export const useTrackOpenFaqItem = (question: string) => {
  return useTrack({
    type: 'openFaqItem',
    payload: {
      question
    }
  });
};

export const useTrackCloseFaqItem = (question: string) => {
  return useTrack({
    type: 'closeFaqItem',
    payload: {
      question
    }
  });
};
