type Callback<T> = (t: T) => void;

export class Subject<T> {
  private _value: T;

  private subscribers: Callback<T>[] = [];

  get value() {
    return this._value;
  }

  constructor(initialValue: T) {
    this._value = initialValue;
  }

  subscribe(cb: Callback<T>) {
    this.subscribers.push(cb);

    return () => {
      const idx = this.subscribers.indexOf(cb);
      if (idx !== -1) {
        this.subscribers.splice(idx, 1);
      }
    };
  }

  next(value: T) {
    this._value = value;
    this.subscribers.forEach((cb) => cb(this._value));
  }
}
