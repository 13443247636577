import React, { useRef, useState } from "react";
import css from "./Gallery.module.css";
import classNames from "classnames";
import { Image } from "../../../helpers/content";
import useSize from "@react-hook/size";
import FullScreenModal from "../../../components/FullScreenModal/FullScreenModal";
import FullScreenImageView from "../FullScreenImageView/FullScreenImageView";
import {useTrackCloseImage, useTrackOpenImage} from "../../../helpers/useTrack/useTrack";

interface Props {
  images: Image[];
}

function Gallery(props: Props) {
  const [
    isFullScreenImageViewModalOpen,
    setIsFullScreenImageViewModalOpen
  ] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const { images } = props;
  const areButtonsVisible = images.length > 1;
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth] = useSize(containerRef);
  const trackOpenImage = useTrackOpenImage();
  const trackCloseImage = useTrackCloseImage();

  const imageNumber1Indexed = imageIndex + 1;
  const openImage = () => {
    trackOpenImage(imageNumber1Indexed);
    setIsFullScreenImageViewModalOpen(true);
  };

  const closeImage = () => {
    trackCloseImage(imageNumber1Indexed);
    setIsFullScreenImageViewModalOpen(false);
  };

  const handleImageClick = (index: number) => {
    if (index === imageIndex) {
      openImage();
    } else {
      setImageIndex(index);
    }
  };
  const width = containerWidth / 2 - 8;
  const maxHeight = "40vh";
  const minHeight = Math.max(...images.map(image => width / image.aspectRatio));

  return (
    <div className={css.container1} ref={containerRef}>
      <div
        className={css.container2}
        style={{ left: `${50 - 50 * imageIndex}%` }}
      >
        {images.map((image, index) =>
          (
            <div key={index} style={{marginRight: `${containerWidth / 2}px`}}>
              <div
                className={css.container3}
                style={{left: `${-50 - 100 * index}%`, width: `${width}px`}}
              >
                <div className={css.imageContainer}>
                  <img
                    src={image.src}
                    alt={image.caption}
                    data-testid={"gallery-image"}
                    draggable={false}
                    style={{
                      maxWidth: `${width}px`,
                      minHeight: `min(${minHeight}px, ${maxHeight})`,
                      maxHeight,
                    }}
                    className={classNames({
                      [css.clickable]: areButtonsVisible && index !== imageIndex,
                      [css.zoomable]: index === imageIndex,
                    })}
                    onClick={() => handleImageClick(index)}
                  />
                  {image.caption && (
                    <div className={css.caption}>{image.caption}</div>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
      {areButtonsVisible && (
        <div data-testid={"gallery-navigation"} className={css.buttonContainer}>
          {images.map((_, index) => (
            <div
              key={index}
              data-testid={"gallery-navigation-button"}
              onClick={() => setImageIndex(index)}
              className={classNames(css.button, {
                [css.active]: imageIndex === index,
              })}
            />
          ))}
        </div>
      )}
      <FullScreenModal isOpen={isFullScreenImageViewModalOpen}>
        <FullScreenImageView
          image={images[imageIndex]}
          onClose={closeImage}
        />
      </FullScreenModal>
    </div>
  );
}

export default Gallery;
