import React from "react";
import css from "./ProgressBar.module.css";

interface Props {
  progress?: number; // between 0 and 100
}

function ProgressBar(props: Props) {
  const { progress } = props;

  return (
    <div className={css.progress} style={{ width: `${progress || 0}%` }} />
  );
}

export default ProgressBar;
