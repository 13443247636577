import React from "react";
import css from "./SubchapterHeader.module.css";
import Header from "../Header/Header";
import HeaderBack from "../Header/HeaderBack";
import Heading from "../Heading/Heading";
import {IconButton} from "@mui/material";

interface Props {
  title?: string;
  onNavigateBack: () => unknown;
}

function SubchapterHeader(props: Props) {
  const { title, onNavigateBack } = props;

  return (
    <Header>
      <div className={css.backButton}>
        <IconButton
          data-testid={'subchapter-header-back'}
          onClick={onNavigateBack}
        >
          <HeaderBack />
        </IconButton>
      </div>
      <Heading>{title}</Heading>
    </Header>
  );
}

export default SubchapterHeader;
