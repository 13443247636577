import React from "react";
import css from "./Button.module.css";
import classNames from "classnames";

interface Props {
  onClick?: () => unknown;
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  type: "red" | "white" | "blue" | "transparent";
}

function Button(props: Props) {
  const { children, onClick, className, disabled, type, ...rest } = props;

  return (
    <button
      {...rest}
      disabled={disabled}
      onClick={onClick}
      className={classNames(
        css.button,
        {
          [css.disabled]: disabled,
          [css.red]: type === "red",
          [css.white]: type === "white",
          [css.blue]: type === "blue",
          [css.transparent]: type === "transparent",
        },
        className
      )}
    >
      {children}
    </button>
  );
}

export default Button;
