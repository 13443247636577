import React from "react";
import {ContentChapterWithNoSubchapter} from "../../helpers/content";
import {isSubchapterRead} from "../../helpers/progress";
import { useFavorite } from "../../helpers/favorites";
import IndexCard from "../IndexCard/IndexCard";
import IndexCardTitle from "../IndexCard/IndexCardTitle";

interface Props {
  chapter: ContentChapterWithNoSubchapter;
}

function ChapterWithoutSubchaptersCard(props: Props) {
  const { chapter } = props;
  const { title, image, number } = chapter;
  const isRead = isSubchapterRead(chapter.id, '1');
  const [isFavorited] = useFavorite(chapter.id, '1');

  return (
    <IndexCard
      isRead={isRead}
      showRead={true}
      image={image}
      alt={title}
      isFavorited={isFavorited}
      title={<IndexCardTitle number={number} title={title} />}
    />
  );

}

export default ChapterWithoutSubchaptersCard;
