import React from "react";
import css from "./IndexCardImage.module.css";

interface Props {
  src: string;
  alt: string;
}

function IndexCardImage(props: Props) {
  const { src, alt } = props;

  return <img src={src} alt={alt} draggable={false} className={css.image} />;
}

export default IndexCardImage;
