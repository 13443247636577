import React, { useContext } from "react";
import {
  IconButton,
  MenuItem,
  Menu,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { useMatch, useNavigate } from "react-router-dom";
import { TranslationsContext } from "../../../helpers/translations";

function IndexMenu() {
  const translations = useContext(TranslationsContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const match = useMatch("/:chapter");
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleBookmarksClick = () => {
    handleClose();

    const chapterId =
      match?.params.chapter != null ? match.params.chapter : undefined;

    if (chapterId) {
      navigate(`/bookmarks?from=/${chapterId}`);
    } else {
      navigate("/bookmarks");
    }
  };

  return (
    <React.Fragment>
      <IconButton
        data-testid={"index-menu-open"}
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          data-testid={"index-menu-bookmarks"}
          onClick={handleBookmarksClick}
        >
          <ListItemIcon>
            <StarRoundedIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText>{translations.bookmarkedPages}</ListItemText>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default IndexMenu;
