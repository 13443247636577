import {useEffect, useRef} from "react";

export const useHasChanged= (val: any) => {
  const prevVal = usePrevious(val)
  return prevVal != null && prevVal !== val
}

const usePrevious = <T>(value: T) => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}
