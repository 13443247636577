import React, { ForwardedRef } from "react";
import css from "./LayoutPage.module.css";
import classNames from "classnames";

interface Props {
  header?: React.ReactNode;
  page?: React.ReactNode;
  pageClassName?: string;
  backgroundColor?: string;
}

const LayoutPage = React.forwardRef(
  (props: Props, ref: ForwardedRef<HTMLDivElement>) => {
    const { header, page, pageClassName, backgroundColor, ...rest } = props;

    return (
      <div ref={ref} className={css.container} {...rest}>
        {header}
        <div
          className={classNames(css.pageContainer, {
            [css.backgroundWhite]: backgroundColor === "white",
            [css.backgroundBlack]: backgroundColor === "black",
          })}
        >
          <div className={classNames(css.page, pageClassName)}>{page}</div>
        </div>
      </div>
    );
  }
);

export default LayoutPage;
