import { Translations } from "./translations";

export interface Content {
  chapters: Chapter[];
  translations: Translations;
  languageCode: "en-US" | "nl-NL";
}

export type Chapter = ContentChapter | FaqChapter | GlossaryChapter | ContentChapterWithNoSubchapter;

export interface ContentChapter {
  id: string;
  number: number;
  title: string;
  image: string;
  subtitle: string;
  subchapters: Subchapter[];
}

export interface ContentChapterWithNoSubchapter {
  id: string;
  number: number;
  title: string;
  image: string;
  pages: [Page, ...Page[]];
}

export interface FaqChapter {
  id: string;
  number: number;
  title: string;
  image: string;
  faqEntries: FaqEntry[];
}

export interface GlossaryChapter {
  id: string;
  number: number;
  title: string;
  image: string;
  glossaryEntries: GlossaryEntry[];
}

export interface Subchapter {
  id: string;
  title: string;
  image: string;
  pages: [Page, ...Page[]];
}

export interface Image {
  src: string;
  caption: string;
  aspectRatio: number;
}

export interface Page {
  images: Image[];
  text: string;
  number: number;
}

export interface FaqEntry {
  question: string;
  answer: string;
}

export interface GlossaryEntry {
  word: string;
  definition: string;
}

export const isContentChapter = (chapter: Chapter): chapter is ContentChapter =>
  !!(chapter as ContentChapter).subchapters;
export const isContentChapterWithoutSubchapters = (chapter: Chapter): chapter is ContentChapterWithNoSubchapter =>
  !!(chapter as ContentChapterWithNoSubchapter).pages;
export const isFaqChapter = (chapter: Chapter): chapter is FaqChapter =>
  !!(chapter as FaqChapter).faqEntries;
export const isGlossaryChapter = (
  chapter: Chapter
): chapter is GlossaryChapter => !!(chapter as GlossaryChapter).glossaryEntries;

export const encodeChapterSubchapter = (
  chapter: Chapter,
  subchapter: Subchapter
) => encodeChapterSubchapterId(chapter.id, subchapter.id);
export const encodeChapterSubchapterId = (
  chapterId: string,
  subchapterId: string
) => `${chapterId}/${subchapterId}`;
export const decodeChapterSubchapterId = (encoded: string) => {
  const [chapterId, subchapterId] = encoded.split("/");
  return { chapterId, subchapterId };
};
