import React from "react";
import { TranslationsContext } from "./helpers/translations";
import { ConfigContextProvider } from "./helpers/config";
import Router from "./Router";
import AppInsightsContextProvider from "./appInsights";
import { useContent } from "./helpers/useContent/useContent";
import { useMergeBookmarks } from "./helpers/useMergeBookmarks/useMergeBookmarks";
import {useSetSavedChapters} from "./helpers/useSetSavedChapters/useSetSavedChapters";

function App() {
  const content = useContent();
  useMergeBookmarks();
  useSetSavedChapters();

  if (!content) {
    return null;
  }

  const { chapters, translations } = content;

  return (
    <ConfigContextProvider>
      <AppInsightsContextProvider>
        <TranslationsContext.Provider value={translations}>
          <Router chapters={chapters} />
        </TranslationsContext.Provider>
      </AppInsightsContextProvider>
    </ConfigContextProvider>
  );
}

export default App;
