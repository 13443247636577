import React, {useContext} from "react";
import css from "./SubchapterEndPageFavorite.module.css";
import TextContainer from "../../../components/TextContainer/TextContainer";
import {IconButton} from "@mui/material";
import {TranslationsContext} from "../../../helpers/translations";
import Check from "../Check/Check";
import {saveProgress} from "../../../helpers/externalActions";
import {saveProgressLocally} from "../../../helpers/progress";
import {useFavorite} from "../../../helpers/favorites";
import {useNavigatePreservingFrom} from "../../../helpers/useNavigatePreservingFrom/useNavigatePreservingFrom";
import {getLastPagePath} from "../getLastPagePath";
import {Page} from "../../../helpers/content";
import LayoutPage from "../../../components/LayoutPage/LayoutPage";
import SubchapterHeader from "../../../components/SubchapterHeader/SubchapterHeader";
import Button from "../../../components/Button/Button";
import {ArrowBackRounded} from "@mui/icons-material";
import FavoriteButton from "../../../components/FavoriteButton/FavoriteButton";
import {useTrackBottomBackButtonClicked, useTrackDoneChapter} from "../../../helpers/useTrack/useTrack";

interface Props {
  chapterId: string;
  subchapterId: string;
  pages: Page[];
  onNavigateBack: () => unknown;
}

function SubchapterEndPageFavorite(props: Props) {
  const { chapterId, subchapterId, pages, onNavigateBack } = props;
  const translations = useContext(TranslationsContext);
  const navigatePreservingFrom = useNavigatePreservingFrom();
  const [isFavorited, toggleFavorite] = useFavorite(chapterId, subchapterId);
  const trackBottomBackButtonClicked = useTrackBottomBackButtonClicked();
  const trackDoneChapter = useTrackDoneChapter();

  const handleSubmit = () => {
    saveProgressLocally(chapterId, subchapterId);
    saveProgress(chapterId, subchapterId);
    trackDoneChapter();
    onNavigateBack();
  };

  const navigateToLastPage = () => navigatePreservingFrom(getLastPagePath(chapterId, subchapterId, pages));

  const onBackClicked = () => {
    trackBottomBackButtonClicked();
    navigateToLastPage();
  };

  return (
    <React.Fragment>
      <LayoutPage
        pageClassName={css.page}
        header={
          <SubchapterHeader
            title={translations.discussWithHealthcareProvider}
            onNavigateBack={onNavigateBack}
          />
        }
        page={
          <div className={css.container}>
            <div className={css.container}>
              <div>
                <Check className={css.checkContainer} />
                <div onClick={toggleFavorite}>
                  <TextContainer className={css.textContainer}>
                    <IconButton
                      disableRipple
                      data-testid={'subchapter-end-page-favorite'}
                      size={'large'}
                    >
                      <FavoriteButton isFavorited={isFavorited} />
                    </IconButton>
                    <div>{translations.bookmarkThisChapter}</div>
                  </TextContainer>
                </div>
                <div className={css.description}>
                  {translations.doYouWantToDiscuss}
                </div>
              </div>
            </div>
            <div className={css.buttonContainer}>
              <Button
                data-click-analytics-id={"subchapter-end-back"}
                data-testid={"subchapter-end-back"}
                type={"red"}
                onClick={onBackClicked}
              >
                <ArrowBackRounded fontSize="large" />
              </Button>
              <div className={css.clearContainer}>
                <Button
                  data-click-analytics-id={"finish"}
                  data-testid={"finish"}
                  className={css.clear}
                  onClick={handleSubmit}
                  type={"red"}
                >
                  {translations.finish}
                </Button>
              </div>
            </div>
          </div>
        }
      />
    </React.Fragment>
  );
}

export default SubchapterEndPageFavorite;
