import { useEffect, useState } from "react";
import { Content } from "../content";
import { useQueryParamValue } from "../useQueryParamValue";

const parseLang = (searchParam: string): "en" | "nl" | undefined => {
  if (searchParam === "en" || searchParam === "nl") {
    return searchParam;
  }

  return undefined;
};

export const useContent = () => {
  const [content, setContent] = useState<Content | undefined>(undefined);
  const language = useQueryParamValue<"en" | "nl">("lang", parseLang, "nl");

  useEffect(() => {
    fetch(`/content/content_${language}.json`)
      .then((response) => response.json())
      .then((result) => setContent(result));
  }, [language]);

  return content;
};
