export const getChapterRoute = (chapterId: string) => `/${chapterId}`;
export const getSubchapterEndRoute = (
  chapterId: string,
  subchapterId: string
) => `/${chapterId}/${subchapterId}/end`;
export const getPageRoute = (
  chapterId: string,
  subchapterId: string,
  pageNumber: number,
  search?: string
) => {
  return `/${chapterId}/${subchapterId}/${pageNumber}${
    search ? `?${search}` : ""
  }`;
};
