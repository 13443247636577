import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useQueryParamValue = <T,>(
  searchParam: string,
  parseValue: (value: string) => T | undefined,
  defaultValue: T
) => {
  const [isValueSaved, setIsValueSaved] = useState(false);
  const [savedValue, setSavedValue] = useState<T>(defaultValue);
  const [searchParams] = useSearchParams();
  const searchParamValue = searchParams.get(searchParam) || "";
  const validatedValue = getValidatedValueFromSearchParamValue(
    searchParamValue,
    parseValue,
    savedValue,
    defaultValue
  );

  useEffect(() => {
    if (!isValueSaved) {
      setIsValueSaved(true);
      setSavedValue(validatedValue);
    }
  }, [isValueSaved, validatedValue, setSavedValue]);

  return validatedValue;
};

const getValidatedValueFromSearchParamValue = <T,>(
  searchParamValue: string,
  parseValue: (value: string) => T | undefined,
  savedValue: T,
  defaultValue: T
): T => {
  const parsedValue = parseValue(searchParamValue);

  if (parsedValue) {
    return parsedValue;
  }

  if (!searchParamValue && savedValue) {
    return savedValue;
  }

  return defaultValue;
};
