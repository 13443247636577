import { sortVoicesByPriority } from "./sortVoicesByPriority";

export const createUtteranceAsync = (
  text: string,
  language: string
): Promise<SpeechSynthesisUtterance> => {
  if (speechSynthesis.getVoices().length) {
    return Promise.resolve(
      createUtterance(text, language, speechSynthesis.getVoices())
    );
  }

  return new Promise((resolve) => {
    speechSynthesis.onvoiceschanged = () => {
      resolve(createUtterance(text, language, speechSynthesis.getVoices()));
    };
  });
};

const createUtterance = (
  text: string,
  language: string,
  voices: SpeechSynthesisVoice[]
) => {
  const preferredVoices = sortVoicesByPriority(voices);
  const preferredVoice = preferredVoices[0];
  const utterance = new window.SpeechSynthesisUtterance(text);
  utterance.lang = language;

  if (preferredVoice) {
    utterance.voice = preferredVoice;
  }

  return utterance;
};
