import { encodeChapterSubchapterId } from "./content";
import { Subject } from "./subject/subject";

export const getChaptersStorage = (localStorageKey: string) => {
  const chaptersStorageUpdateSubject = new Subject<void>(undefined);

  const getMarked = (): string[] => {
    const json = localStorage.getItem(localStorageKey);
    return json == null ? [] : JSON.parse(json);
  };

  const isSubchapterMarked = (chapterId: string, subchapterId: string) => {
    const marked = getMarked();
    return marked.includes(encodeChapterSubchapterId(chapterId, subchapterId));
  };

  const markSubchapter = (chapterId: string, subchapterId: string) => {
    const isMarked = isSubchapterMarked(chapterId, subchapterId);

    if (isMarked) {
      return;
    }

    const updatedMarked = [
      ...getMarked(),
      encodeChapterSubchapterId(chapterId, subchapterId),
    ];
    localStorage.setItem(localStorageKey, JSON.stringify(updatedMarked));
    chaptersStorageUpdateSubject.next();
  };

  const unmarkSubchapter = (chapterId: string, subchapterId: string) => {
    const updatedMarked = getMarked().filter(
      (chapterSubchapter) =>
        chapterSubchapter !== `${chapterId}/${subchapterId}`
    );
    localStorage.setItem(localStorageKey, JSON.stringify(updatedMarked));
    chaptersStorageUpdateSubject.next();
  };

  const clear = () => {
    localStorage.setItem(localStorageKey, JSON.stringify([]));
    chaptersStorageUpdateSubject.next();
  };

  return {
    markSubchapter,
    unmarkSubchapter,
    isSubchapterMarked,
    clear,
    subscribe: (cb: () => unknown) =>
      chaptersStorageUpdateSubject.subscribe(cb),
  };
};
