import React, { useContext } from "react";
import CheckRounded from "@mui/icons-material/CheckRounded";
import css from "./Check.module.css";
import { TranslationsContext } from "../../../helpers/translations";
import classNames from "classnames";

interface Props {
  className?: string;
}

function Check(props: Props) {
  const translations = useContext(TranslationsContext);
  const { className } = props;

  return (
    <div className={classNames(css.checkContainer, className)}>
      <div className={css.check}>
        <CheckRounded fontSize="inherit" />
      </div>
      <div className={css.checkText}>{translations.chapterCompleted}</div>
    </div>
  );
}

export default Check;
