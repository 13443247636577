import React from "react";
import css from "./HeaderTitle.module.css";

interface Props {
  children?: React.ReactNode;
}

function HeaderTitle(props: Props) {
  const { children } = props;

  return <div className={css.headerTitle}>{children}</div>;
}

export default HeaderTitle;
