import React, { useContext } from "react";
import css from "./IndexHeader.module.css";
import Header from "../Header/Header";
import HeaderBack from "../Header/HeaderBack";
import { Link } from "react-router-dom";
import { TranslationsContext } from "../../helpers/translations";
import IndexMenu from "./IndexMenu/IndexMenu";
import HeaderTitle from "../HeaderTitle/HeaderTitle";
import {IconButton} from "@mui/material";

interface Props {
  onBackClick?: () => unknown;
}

function IndexHeader(props: Props) {
  const { onBackClick } = props;
  const translations = useContext(TranslationsContext);

  return (
    <Header>
      <div className={css.headerContainerOuter}>
        <div className={css.headerContainer}>
          <Link
            to={"./.."}
            className={css.backButton}
            onClick={onBackClick}
            data-click-analytics-id={'index-header-back-button'}
            data-testid={"index-header-back-button"}
          >
            <IconButton>
              <HeaderBack />
            </IconButton>
          </Link>
          <HeaderTitle>{translations.diabetesHandbook}</HeaderTitle>
        </div>
        <IndexMenu />
      </div>
    </Header>
  );
}

export default IndexHeader;
