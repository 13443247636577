import { useNavigate } from "react-router-dom";
import { NavigateOptions } from "react-router/lib/hooks";
import { useFrom } from "../useFrom";

export const useNavigatePreservingFrom = () => {
  const navigate = useNavigate();
  const from = useFrom();
  const navigatePreservingFrom = (
    pathname: string,
    options?: NavigateOptions
  ) =>
    navigate(
      {
        pathname,
        search: from ? `?from=${from}` : "",
      },
      options
    );

  return (path: string, options?: NavigateOptions) =>
    navigatePreservingFrom(path, options);
};
