import React from "react";
import LayoutPage from "../../components/LayoutPage/LayoutPage";
import BookmarksHeader from "./BookmarksHeader";
import {Chapter, isContentChapter, isContentChapterWithoutSubchapters} from "../../helpers/content";
import Heading from "../../components/Heading/Heading";
import { isSubchapterFavorited } from "../../helpers/favorites";
import SubchapterCard from "../../components/SubchapterCard/SubchapterCard";
import SpacingContainer from "../../components/SpacingContainer/SpacingContainer";
import { Link } from "react-router-dom";
import { getPageRoute } from "../../helpers/navigation";
import css from "./BookmarksPage.module.css";
import { getNumberedChapterTitle } from "../../helpers/getNumberedChapterTitle";
import ChapterWithoutSubchaptersCard
  from "../../components/ChapterWithoutSubchaptersCard/ChapterWithoutSubchaptersCard";

interface Props {
  chapters: Chapter[];
}

function BookmarksPage(props: Props) {
  const { chapters } = props;
  const favoritedContentChaptersWithoutSubchapters = chapters
    .filter(isContentChapterWithoutSubchapters)
    .filter(chapter => isSubchapterFavorited(chapter.id, '1'));
  const contentChapters = chapters.filter(isContentChapter);

  return (
    <LayoutPage
      header={<BookmarksHeader />}
      page={
        <SpacingContainer>
          {favoritedContentChaptersWithoutSubchapters.map(chapter => {
            const pagePath = getPageRoute(
              chapter.id,
              '1',
              1,
              "from=/bookmarks"
            );

            return (
              <SpacingContainer key={chapter.id}>
                <Link
                  data-click-analytics-id={pagePath}
                  key={pagePath}
                  to={pagePath}
                  className={css.link}
                >
                  <ChapterWithoutSubchaptersCard chapter={chapter} />
                </Link>
              </SpacingContainer>
            )
          })}
          {contentChapters.map((chapter) => {
            const bookmarkedSubchapters = chapter.subchapters.filter(
              (subchapter) => {
                return isSubchapterFavorited(chapter.id, subchapter.id);
              }
            );

            if (!bookmarkedSubchapters.length) {
              return null;
            }

            const chapterTitle = getNumberedChapterTitle(chapter);

            return (
              <SpacingContainer key={chapter.id}>
                <Heading>{chapterTitle}</Heading>
                {bookmarkedSubchapters.map((subchapter) => {
                  const pagePath = getPageRoute(
                    chapter.id,
                    subchapter.id,
                    1,
                    "from=/bookmarks"
                  );
                  return (
                    <Link
                      data-click-analytics-id={pagePath}
                      key={pagePath}
                      to={pagePath}
                      className={css.link}
                    >
                      <SubchapterCard
                        chapter={chapter}
                        subchapter={subchapter}
                      />
                    </Link>
                  );
                })}
              </SpacingContainer>
            );
          })}
        </SpacingContainer>
      }
    />
  );
}

export default BookmarksPage;
