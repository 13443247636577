import React from "react";
import css from "./Heading.module.css";

interface Props {
  children?: React.ReactNode;
}

function Heading(props: Props) {
  const { children } = props;

  return <h1 className={css.heading}>{children}</h1>;
}

export default Heading;
