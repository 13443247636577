import React from "react";
import Card from "../Card/Card";
import IndexCardImage from "../IndexCard/IndexCardImage";
import css from "./IndexCard.module.css";
import classNames from "classnames";
import FavoriteButton from "../FavoriteButton/FavoriteButton";
import {IconButton} from "@mui/material";

interface Props {
  isRead: boolean;
  isFavorited: boolean;
  showRead: boolean;
  title: React.ReactNode;
  alt: string;
  image: string;
}

function IndexCard(props: Props) {
  const { isRead, isFavorited, showRead, alt, title, image } = props;

  return (
    <Card>
      <div className={css.outerContainer}>
        <div className={css.innerContainer}>
          <IndexCardImage src={image} alt={alt} />
          {title}
        </div>
        {isFavorited && (
          <IconButton data-testid={"favorited-star"} disabled={true}>
            <FavoriteButton isFavorited={true} />
          </IconButton>
        )}
      </div>
      {showRead && <div className={classNames(css.progress, { [css.read]: isRead })} />}
    </Card>
  );
}

export default IndexCard;
