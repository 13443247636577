export const sortVoicesByPriority = (voices: SpeechSynthesisVoice[]): SpeechSynthesisVoice[] => {
  return voices
    .map((voice) => ({
      voice,
      priority: getVoicePriority(voice),
    }))
    .sort((voice1, voice2) => voice2.priority - voice1.priority)
    .map(({ voice }) => voice);
};

const getVoicePriority = (voice: SpeechSynthesisVoice): number => {
  const isNl = voice.lang.startsWith('nl');
  const isEn = voice.lang.startsWith('en');

  if (isNl && voice.localService) {
    return 5;
  }

  if (isEn && voice.localService) {
    return 4;
  }

  if (voice.localService) {
    return 3;
  }

  if (isNl) {
    return 2;
  }

  if (isEn) {
    return 1;
  }

  return 0;
};
