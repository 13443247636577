import React, { ReactNode } from "react";
import css from "./Card.module.css";
import classNames from "classnames";

interface Props {
  children?: ReactNode;
  className?: string;
  onClick?: () => unknown;
}

function Card(props: Props) {
  const { children, onClick, className } = props;

  return (
    <div className={classNames(css.card, className)} onClick={onClick}>
      {children}
    </div>
  );
}

export default Card;
