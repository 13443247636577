import React from "react";
import { Image } from "../../../helpers/content";
import LayoutPage from "../../../components/LayoutPage/LayoutPage";
import css from "./FullScreenImageView.module.css";
import Close from "@mui/icons-material/Close";

interface Props {
  image: Image;
  onClose: () => unknown;
}

function FullScreenImageView(props: Props) {
  const { image, onClose } = props;

  return (
    <LayoutPage
      backgroundColor={"black"}
      pageClassName={css.page}
      page={
        <div className={css.pageContainer}>
          <div className={css.buttonContainer} onClick={onClose}>
            <Close
              data-testid={"full-screen-image-view-close-button"}
              className={css.closeButton}
              fontSize="inherit"
            />
          </div>
          <div className={css.contentContainer}>
            <div className={css.imageContainer}>
              <img
                src={image.src}
                alt={image.caption}
                draggable={false}
                className={css.image}
              />
            </div>
            <div className={css.caption}>{image.caption}</div>
          </div>
        </div>
      }
    />
  );
}

export default FullScreenImageView;
