import {decodeChapterSubchapterId} from "./content";

export const parseChapterSubchaptersFromQueryParams = (searchParam: string): string[] | undefined => {
  const chapterSubchapters = decodeURIComponent(searchParam).split(",");
  const isValid = chapterSubchapters.every((chapterSubchapter) => {
    const { chapterId, subchapterId } = decodeChapterSubchapterId(chapterSubchapter);
    return +chapterId > 0 && +subchapterId > 0;
  });

  if (!isValid) {
    return undefined;
  }

  return chapterSubchapters;
}
