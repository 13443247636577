import React from "react";
import { useWebTts } from "./useWebTts";
import AudioPresentational from "./AudioPresentational";

interface Props {
  text: string;
  onPlay: () => unknown;
  onStop: () => unknown;
}

function WebAudio(props: Props) {
  const { text, onPlay, onStop } = props;
  const [play, stop, playing] = useWebTts(text);

  return <AudioPresentational play={play} stop={stop} playing={playing} onPlay={onPlay} onStop={onStop} />;
}

export default WebAudio;
