import React  from "react";
import css from "./SubchapterPageHeader.module.css";
import Header from "../../../components/Header/Header";
import HeaderBack from "../../../components/Header/HeaderBack";
import Heading from "../../../components/Heading/Heading";
import FavoriteButton from "../../../components/FavoriteButton/FavoriteButton";
import { useFavorite } from "../../../helpers/favorites";
import { IconButton } from "@mui/material";

interface Props {
  title: string;
  chapterId: string;
  subchapterId: string;
  onNavigateBack: () => unknown;
}

function SubchapterPageHeader(props: Props) {
  const { title, chapterId, subchapterId, onNavigateBack } = props;
  const [isFavorited, toggleFavorite] = useFavorite(chapterId, subchapterId);

  return (
    <Header>
      <div className={css.headerContainerOuter}>
        <div className={css.headerContainer}>
          <div className={css.backButton}>
            <IconButton
              data-testid={'subchapter-page-header-back'}
              onClick={onNavigateBack}
            >
              <HeaderBack />
            </IconButton>
          </div>
          <Heading>{title}</Heading>
        </div>
        <IconButton onClick={toggleFavorite}>
          <FavoriteButton isFavorited={isFavorited} />
        </IconButton>
      </div>
    </Header>
  );
}

export default SubchapterPageHeader;
