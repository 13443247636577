import React from "react";
import FaqEntryPanel from "./FaqEntryPanel";
import LayoutPage from "../../components/LayoutPage/LayoutPage";
import IndexHeader from "../../components/IndexHeader/IndexHeader";
import SpacingContainer from "../../components/SpacingContainer/SpacingContainer";
import Heading from "../../components/Heading/Heading";
import { FaqEntry } from "../../helpers/content";

interface Props {
  title: string;
  faqEntries: FaqEntry[];
}

function FaqPage(props: Props) {
  const { title, faqEntries } = props;

  return (
    <LayoutPage
      header={<IndexHeader />}
      page={
        <SpacingContainer>
          <Heading>{title}</Heading>
          {faqEntries.map((entry) => {
            return <FaqEntryPanel key={entry.question} entry={entry} />;
          })}
        </SpacingContainer>
      }
    />
  );
}

export default FaqPage;
