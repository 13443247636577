import React, { useState } from "react";
import { FaqEntry } from "../../helpers/content";
import Card from "../../components/Card/Card";
import css from "./FaqEntryPanel.module.css";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Markdown from "../../components/Markdown/Markdown";
import {useTrackCloseFaqItem, useTrackOpenFaqItem} from "../../helpers/useTrack/useTrack";

interface Props {
  entry: FaqEntry;
}

function FaqEntryPanel(props: Props) {
  const [expanded, setExpanded] = useState(false);
  const { question, answer } = props.entry;
  const trackOpenFaqItem = useTrackOpenFaqItem(question);
  const trackCloseFaqItem = useTrackCloseFaqItem(question);
  const onClick = () => {
    if (expanded) {
      trackCloseFaqItem();
    } else {
      trackOpenFaqItem();
    }

    setExpanded((isExpanded) => !isExpanded);
  };

  return (
    <Card
      className={css.card}
      onClick={onClick}
    >
      <div data-testid="expand" className={css.expand}>
        {expanded ? <ExpandLess /> : <ExpandMore />}
      </div>
      <div>
        <div className={css.question}>{question}</div>
        {expanded && (
          <Markdown
            className={css.answer}
            customMarkdownComponents={{
              p: props => <p className={css.answerParagraph} {...props} />,
              ul: props => <ul className={css.answerList} {...props} />
            }}
          >
            {answer}
          </Markdown>
        )}
      </div>
    </Card>
  );
}

export default FaqEntryPanel;
