import {encodeChapterSubchapterId} from "./content";
import { extendedWindow } from "./extendedWindow";

interface CloseCommand {
  command: "close";
}

interface SaveProgressCommand {
  command: "save";
  chapterSubchapter: string;
}

interface SaveFavoriteCommand {
  command: "saveFavorite";
  chapterSubchapter: string;
}

interface RemoveFavoriteCommand {
  command: "removeFavorite";
  chapterSubchapter: string;
}

interface TextToSpeechPlay {
  command: "ttsPlay";
  ttsText: string;
  ttsLanguage: string;
}

interface TextToSpeechStop {
  command: "ttsStop";
}

type Command =
  | CloseCommand
  | SaveProgressCommand
  | TextToSpeechPlay
  | TextToSpeechStop
  | SaveFavoriteCommand
  | RemoveFavoriteCommand;

const invokeCSharpAction = (command: Command) => {
  const json = JSON.stringify(command);
  extendedWindow.invokeCSharpAction?.(json);
};

export const close = () => {
  invokeCSharpAction({
    command: "close",
  });
  window.parent?.postMessage("close", "*");
};

export const saveProgress = (chapterId: string, subchapterId: string) => {
  invokeCSharpAction({
    command: "save",
    chapterSubchapter: encodeChapterSubchapterId(chapterId, subchapterId),
  });
};

export const saveFavorite = (chapterId: string, subchapterId: string) => {
  invokeCSharpAction({
    command: "saveFavorite",
    chapterSubchapter: encodeChapterSubchapterId(chapterId, subchapterId),
  });
};

export const removeFavorite = (chapterId: string, subchapterId: string) => {
  invokeCSharpAction({
    command: "removeFavorite",
    chapterSubchapter: encodeChapterSubchapterId(chapterId, subchapterId),
  });
};


export const textToSpeechPlay = (ttsText: string, ttsLanguage: string) => {
  invokeCSharpAction({
    command: "ttsPlay",
    ttsText,
    ttsLanguage,
  });
};

export const textToSpeechStop = () => {
  invokeCSharpAction({
    command: "ttsStop",
  });
};
