import React from "react";

export const interpolate = (template: string, values: (string | number)[]) => {
  const stringValues = values.map((value) => `${value}`);
  return stringValues.reduce((result, value, index) => {
    return result.replace(`{${index}}`, value);
  }, template);
};

export type LanguageCode = "en-US" | "nl-NL";

export interface Translations {
  languageCode: LanguageCode;
  diabetesHandbook: string;
  goodAnswer: string;
  wrongAnswer: string;
  cancel: string;
  confirm: string;
  knowledgeTest: string;
  next: string;
  discussWithHealthcareProvider: string;
  chapterCompleted: string;
  iWantToDiscuss: string;
  finish: string;
  readAloud: string;
  pageOf: string;
  possibleWithAccount: string;
  createMiGuideAccount: string;
  toUseThisPartYouNeedAccount: string;
  insight: string;
  coaching: string;
  challenges: string;
  createAccount: string;
  maybeLater: string;
  bookmarkedPages: string;
  bookmarkThisChapter: string;
  doYouWantToDiscuss: string;
  createAccountImageAlt: string;
  miGuideLogoAlt: string;
}

export const TranslationsContext = React.createContext<Translations>({
  languageCode: "en-US",
  diabetesHandbook: "",
  goodAnswer: "",
  wrongAnswer: "",
  cancel: "",
  confirm: "",
  knowledgeTest: "",
  next: "",
  discussWithHealthcareProvider: "",
  chapterCompleted: "",
  iWantToDiscuss: "",
  finish: "",
  readAloud: "",
  pageOf: "",
  possibleWithAccount: "",
  createMiGuideAccount: "",
  toUseThisPartYouNeedAccount: "",
  insight: "",
  coaching: "",
  challenges: "",
  createAccount: "",
  maybeLater: "",
  bookmarkedPages: "",
  bookmarkThisChapter: "",
  doYouWantToDiscuss: "",
  createAccountImageAlt: "",
  miGuideLogoAlt: ""
});
