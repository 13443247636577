import { useEffect } from "react";
import { useQueryParamValue } from "../useQueryParamValue";
import { decodeChapterSubchapterId } from "../content";
import {clearFavorites, favoriteLocally} from "../favorites";
import {parseChapterSubchaptersFromQueryParams} from "../parseChaptersSubchaptersFromQueryParams";

export const useSetSavedChapters = () => {
  const savedChapters = useQueryParamValue<string[]>(
    "savedChapters",
    parseChapterSubchaptersFromQueryParams,
    []
  );

  useEffect(() => {
    if (!savedChapters.length) {
      return;
    }
    clearFavorites();
    savedChapters.forEach((savedChapter) => {
      const { chapterId, subchapterId } = decodeChapterSubchapterId(savedChapter);
      favoriteLocally(chapterId, subchapterId);
    });
  }, [savedChapters]);
};
