import React, { useContext, useEffect, useState } from "react";
import { ApplicationInsights, ITelemetryItem } from "@microsoft/applicationinsights-web";
import {
  AppInsightsContext,
  ReactPlugin,
} from "@microsoft/applicationinsights-react-js";
import history from "./history";
import { ConfigContext } from "./helpers/config";

interface AppInsightsConfig {
  authenticatedUserId?: string;
  endpointUrl?: string;
}

const connectionString = process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING || "";

const configureAppInsightsReactPlugin = (config: AppInsightsConfig) => {
  if (!connectionString) {
    return createDebugReactPlugin();
  }

  return createReactPlugin(config);
};

const getNameFromEnvelope = (envelope: ITelemetryItem): string => {
  const url = String(envelope.baseData?.uri || '');

  if (!url) {
    return 'Pharos';
  }

  if (url === '/') {
    return 'Index';
  }

  if (url === '/bookmarks') {
    return 'Bookmarks';
  }

  const urlParts = url.split('/').filter(part => part !== '/' && !!part);
  const pathLength = urlParts.length;

  if (pathLength === 1) {
    return 'Chapter index';
  }

  if (pathLength === 3) {
    if (urlParts[2] === 'end') {
      return 'Subchapter end';
    }
    return 'Content page';
  }

  return 'Pharos';
};

const telemetryInitializer = (envelope: ITelemetryItem) => {
  delete envelope.tags?.['ai.operation.id'];

  if (envelope.baseType === 'PageviewPerformanceData') {
    return false;
  }

  if (envelope.baseType === 'PageviewData' && envelope.baseData?.name) {
    envelope.baseData.name = getNameFromEnvelope(envelope);
  }

  return true;
};

const createDebugReactPlugin = (): ReactPlugin => {
  const reactPlugin = new ReactPlugin();
  reactPlugin.trackEvent = (...args) => console.log('App Insights debug:', args);
  return reactPlugin;
};

const createReactPlugin = (config: AppInsightsConfig): ReactPlugin => {
  const reactPlugin = new ReactPlugin();
  const reactPluginConfig = { history };

  const appInsights = new ApplicationInsights({
    config: {
      connectionString,
      endpointUrl: config.endpointUrl,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: reactPluginConfig
      },
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      correlationHeaderExcludedDomains: ['*.queue.core.windows.net']
    },
  });

  appInsights.loadAppInsights();

  if (config.authenticatedUserId) {
    appInsights.setAuthenticatedUserContext(config.authenticatedUserId);
  }

  appInsights.addTelemetryInitializer(telemetryInitializer);

  return reactPlugin;
};

interface Props {
  children?: React.ReactNode;
  endpointUrl?: string;
}

function AppInsightsContextProvider(props: Props) {
  const [appInsightsReactPlugin, setAppInsightsReactPlugin] = useState<ReactPlugin | null>(null);
  const config = useContext(ConfigContext);
  const { children, endpointUrl } = props;

  useEffect(() => {
    const plugin = configureAppInsightsReactPlugin({
      authenticatedUserId: config.userId,
      endpointUrl,
    });
    setAppInsightsReactPlugin(plugin);
  }, [config.userId, endpointUrl]);

  if (!appInsightsReactPlugin) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <AppInsightsContext.Provider value={appInsightsReactPlugin}>
      {children}
    </AppInsightsContext.Provider>
  );
}

export default AppInsightsContextProvider;
