import React from "react";
import { Chapter } from "../../helpers/content";
import IndexCard from "../IndexCard/IndexCard";
import IndexCardTitle from "../IndexCard/IndexCardTitle";

interface Props {
  chapter: Chapter;
}

function ChapterCard(props: Props) {
  const { image, title, number } = props.chapter;
  return (
    <IndexCard
      isRead={false}
      showRead={false}
      image={image}
      alt={title}
      isFavorited={false}
      title={<IndexCardTitle number={number} title={title} />}
    />
  );
}

export default ChapterCard;
