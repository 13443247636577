import React from "react";
import css from "./FullScreenModal.module.css";
import { DialogContent } from "@mui/material";
import ModalUnstyled from "@mui/base/ModalUnstyled";

interface Props {
  isOpen: boolean;
  children?: React.ReactNode;
}

function FullScreenModal(props: Props) {
  const { isOpen, children } = props;

  return (
    <ModalUnstyled open={isOpen} className={css.modal}>
      <DialogContent classes={{ root: css.modalContent }}>
        {children}
      </DialogContent>
    </ModalUnstyled>
  );
}

export default FullScreenModal;
