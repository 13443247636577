import React from "react";
import css from "./IndexCardTitle.module.css";

interface Props {
  number: number;
  title: string;
}

function IndexCardTitle(props: Props) {
  const { number, title } = props;

  return (
    <div className={css.textContainer}>
      <span className={css.number}>{number}</span>
      <span>{title}</span>
    </div>
  );
}

export default IndexCardTitle;
