import React from "react";
import GlossaryEntryBlock from "./GlossaryEntry";
import LayoutPage from "../../components/LayoutPage/LayoutPage";
import IndexHeader from "../../components/IndexHeader/IndexHeader";
import Heading from "../../components/Heading/Heading";
import { GlossaryEntry } from "../../helpers/content";

interface Props {
  title: string;
  glossaryEntries: GlossaryEntry[];
}

function GlossaryPage(props: Props) {
  const { title, glossaryEntries } = props;

  return (
    <LayoutPage
      header={<IndexHeader />}
      backgroundColor={"white"}
      page={
        <React.Fragment>
          <Heading>{title}</Heading>
          {glossaryEntries.map((entry) => {
            return <GlossaryEntryBlock key={entry.word} entry={entry} />;
          })}
        </React.Fragment>
      }
    />
  );
}

export default GlossaryPage;
