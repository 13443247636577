import {useCallback, useContext, useSyncExternalStore} from "react";
import { TranslationsContext } from "../../../helpers/translations";
import {
  textToSpeechPlay,
  textToSpeechStop,
} from "../../../helpers/externalActions";
import { UseTtsReturnValue } from "./tts";
import { Subject } from "../../../helpers/subject/subject";
import { extendedWindow } from "../../../helpers/extendedWindow";

const ttsPlayingSubject = new Subject(false);
extendedWindow.setTtsPlaying = (value) => ttsPlayingSubject.next(value);

export const useMobileTts = (text: string): UseTtsReturnValue => {
  const translations = useContext(TranslationsContext);
  const playing = useSyncExternalStore(
    (cb) => ttsPlayingSubject.subscribe(cb),
    () => ttsPlayingSubject.value
  );

  const play = useCallback(() => textToSpeechPlay(text, translations.languageCode), [text, translations.languageCode]);
  const stop = useCallback(() => textToSpeechStop(), []);

  return [play, stop, playing];
};
