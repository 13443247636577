import React from "react";
import css from "./Markdown.module.css";
import ReactMarkdown, { Options as ReactMarkdownOptions } from "react-markdown";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { TransformLink } from "react-markdown/lib/ast-to-react";
import {isExternalLink} from "../../helpers/links";

interface Props {
  children: string;
  className?: string;
  transformLinkUri?: TransformLink;
  customMarkdownComponents?: ReactMarkdownOptions['components']
}

function Markdown(props: Props) {
  const { children, className, transformLinkUri, customMarkdownComponents } = props;

  return (
    <ReactMarkdown
      className={classNames(css.markdown, className)}
      transformLinkUri={transformLinkUri}
      components={{
        a: ({ href, ...props }) => {
          const link = href || "";
          if (isExternalLink(link)) {
            return (
              <a
                href={link}
                target={'_blank'}
                rel={'noreferrer'}
                {...props}
              >
                {props.children}
              </a>
            );
          } else {
            return (
              <Link
                data-click-analytics-id={link}
                to={link} {...props}
              />);
          }
        },
        ...customMarkdownComponents
      }}
    >
      {children}
    </ReactMarkdown>
  );
}

export default Markdown;
