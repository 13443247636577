import { getChaptersStorage } from "./getChaptersStorage";
import {useSyncExternalStore} from "react";
import {removeFavorite, saveFavorite} from "./externalActions";
import {useTrackFavoriteChapter, useTrackUnfavoriteChapter} from "./useTrack/useTrack";

export const pharosFavoritesLocalStorageKey = "pharos-favorites";

const {
  isSubchapterMarked,
  markSubchapter,
  unmarkSubchapter,
  clear,
  subscribe,
} = getChaptersStorage(pharosFavoritesLocalStorageKey);

export const favoriteLocally = markSubchapter;
export const unfavoriteLocally = unmarkSubchapter;
export const isSubchapterFavorited = isSubchapterMarked;
export const clearFavorites = clear;
export const subscribeToFavoritesUpdates = subscribe;

export const useFavorite = (chapterId: string, subchapterId: string): [boolean, () => unknown] => {
  const trackFavoriteChapter = useTrackFavoriteChapter();
  const trackUnfavoriteChapter = useTrackUnfavoriteChapter();
  const isFavorited = useSyncExternalStore(
    (cb) => subscribeToFavoritesUpdates(cb),
    () => isSubchapterFavorited(chapterId, subchapterId)
  );

  const toggleFavorite = () => {
    if (isFavorited) {
      trackUnfavoriteChapter();
      unfavoriteLocally(chapterId, subchapterId);
      removeFavorite(chapterId, subchapterId);
    } else {
      trackFavoriteChapter();
      favoriteLocally(chapterId, subchapterId);
      saveFavorite(chapterId, subchapterId);
    }
  };

  return [isFavorited, toggleFavorite];
};
