import React, { useContext } from "react";
import css from "./BookmarksHeader.module.css";
import { Link } from "react-router-dom";
import HeaderBack from "../../components/Header/HeaderBack";
import Header from "../../components/Header/Header";
import { TranslationsContext } from "../../helpers/translations";
import HeaderTitle from "../../components/HeaderTitle/HeaderTitle";
import { useFrom } from "../../helpers/useFrom";
import {IconButton} from "@mui/material";

function BookmarksHeader() {
  const translations = useContext(TranslationsContext);
  const from = useFrom();
  const to = from || "./..";

  return (
    <Header>
      <Link
        data-click-analytics-id={to}
        to={to}
        className={css.backButton}
        data-testid={"index-header-back-button"}
      >
        <IconButton>
          <HeaderBack />
        </IconButton>
      </Link>
      <HeaderTitle>{translations.bookmarkedPages}</HeaderTitle>
    </Header>
  );
}

export default BookmarksHeader;
